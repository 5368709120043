'use client';

import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { CloseMiniIcon, PlusMiniIcon } from '../icons';
import { useTranslations } from 'next-intl';
import { CLIENT_COOKIE_KEY } from '@/i18n/config';

export function CookieConsentBanner() {
  const t = useTranslations();

  const [showBanner, setShowBanner] = useState(false);
  const [showPreference, setShowPreference] = useState(false);

  useEffect(() => {
    const consent = Cookies.get(CLIENT_COOKIE_KEY);
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set(CLIENT_COOKIE_KEY, 'en', { expires: 365 });
    setShowBanner(false);
    setShowPreference(false);
  };

  const handlePreference = () => {
    setShowPreference(true);
  };

  const handleClosePreference = () => {
    setShowPreference(false);
  };

  const handleConfirm = () => {
    Cookies.set(CLIENT_COOKIE_KEY, 'en', { expires: 365 });
    setShowBanner(false);
    setShowPreference(false);
  };

  const handleReactAll = () => {
    Cookies.set(CLIENT_COOKIE_KEY, 'en', { expires: 365 });
    setShowPreference(false);
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <section className="fixed bottom-0 flex justify-center w-full bg-white shadow-lg z-20">
          <div className="main-sized-wrapper px-5 md:px-0 py-8">
            <div className="w-full flex flex-col lg:flex-row justify-between gap-6 lg:gap-8 xl:gap-32">
              <div className="w-full lg:w-3/5 xl:w-2/3 flex flex-col gap-2.5">
                <div className="font-notoserif text-2xl font-semibold leading-normal text-grey-13">
                  {t('cookie-banner-your-privacy-our-policy')}
                </div>

                <div className="font-notosans text-sm leading-normal text-grey-10">
                  {t('cookie-banner-banner-description')}
                </div>
              </div>

              <div className="w-full lg:w-2/5 xl:w-1/3 flex flex-row gap-8 xl:gap-10 my-auto justify-between lg:justify-end">
                <div
                  className="font-notosans text-base leading-normal text-grey-12 underline decoration-solid underline-offset-2 cursor-pointer my-auto"
                  onClick={handlePreference}
                >
                  {t('cookie-banner-manage-preferences')}
                </div>

                <div
                  className="px-10 py-2.5 rounded-custom bg-primary-2 flex justify-center items-center gap-2.5 cursor-pointer"
                  onClick={handleAccept}
                >
                  <span className="text-white font-notosans text-base leading-normal">
                    {t('cookie-banner-banner-accept')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {showPreference && (
        <section
          className="fixed w-full h-full top-0 z-[100] flex justify-center items-center"
          style={{ background: 'rgba(0, 0, 0, 0.16)' }}
        >
          <div className="bg-grey-1 rounded-custom flex flex-col w-cookieSelectionModalContent px-3 py-6 max-h-[640px] h-fit overflow-y-auto">
            <div className="w-full flex flex-col gap-6">
              <div className="flex justify-between w-full">
                <div className="font-notoserif text-1.5xl leading-7 font-bold text-grey-13">
                  {t('cookie-preferences-about-your-privacy')}
                </div>

                <CloseMiniIcon
                  onClick={handleClosePreference}
                  className="cursor-pointer text-grey-13"
                />
              </div>

              <div className="font-notosans text-sm leading-custom_3 text-grey-10">
                {t('cookie-preferences-preferences-description-first')}
              </div>

              <div className="font-notosans text-sm leading-custom_3 text-grey-10">
                {t('cookie-preferences-preferences-description-second')}
                <br />
                <a href="/cookie-policy" className="underline">
                  {t('cookie-preferences-view-our-cookie-policy')}
                </a>
              </div>

              <div className="w-full flex flex-col gap-4 mt-2">
                <div className="font-notoserif text-1.5xl font-bold leading-7 text-grey-13">
                  {t('cookie-preferences-manage-consent-preferences')}
                </div>

                <div className="w-full flex flex-col gap-3">
                  <div className="w-full flex flex-row justify-between pb-2.5 border-b border-b-grey-4">
                    <div className="flex flex-row gap-2">
                      <PlusMiniIcon className="my-auto text-[#1c1c1e]" />

                      <div className="font-notoserif text-base font-medium leading-normal text-grey-13">
                        {t('cookie-preferences-functional-cookies')}
                      </div>
                    </div>

                    <div className="font-notosans text-xs2 font-semibold leading-normal text-primary-1">
                      {t('cookie-preferences-always-active')}
                    </div>
                  </div>

                  <div className="w-full flex flex-row justify-between pb-2.5 border-b border-b-grey-4">
                    <div className="flex flex-row gap-2">
                      <CloseMiniIcon className="my-auto text-[#1c1c1e]" />

                      <div className="font-notoserif text-base font-medium leading-normal text-grey-13">
                        {t('cookie-preferences-functional-cookies')}
                      </div>
                    </div>

                    <div className="font-notosans text-xs2 font-semibold leading-normal text-primary-1">
                      {t('cookie-preferences-always-active')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between gap-4 md:gap-8 mt-2">
                <div
                  className="px-10 py-2.5 rounded-custom bg-primary-2 flex justify-center items-center gap-2.5 w-full md:w-fit cursor-pointer"
                  onClick={handleReactAll}
                >
                  <span className="font-notosans text-base leading-normal text-white">
                    {t('cookie-preferences-reject-all')}
                  </span>
                </div>

                <div
                  className="px-10 py-2.5 rounded-custom bg-primary-2 flex justify-center items-center gap-2.5 w-full md:w-fit cursor-pointer"
                  onClick={handleConfirm}
                >
                  <span className="font-notosans text-base leading-normal text-white">
                    {t('cookie-preferences-confirm-my-preferences')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
